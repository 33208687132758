@import "/public/assets/style/variables";

.blur {
  filter: blur(20px);
  animation: blur 0.2s linear forwards;
  width: 90%;
  max-width: 1450px;
  margin-left: auto;
  margin-right: auto;
}

@keyframes blur {
  0% {
    filter: blur(20px);
  }

  100% {
    filter: none;
  }
}

.header {
  @include display-center(flex, space-between, center);
  padding: 10px 20px !important;
  background: linear-gradient(to right, #7368f0, #9e95f6);
  color: white;
  border-radius: 1rem;
  margin-bottom: 1rem;

  .title {
    font-size: 22px;
    font-weight: 700;
    //margin-bottom: 2px;
  }
  .buttons {
    Button {
      margin-bottom: 0;
      margin-top: 0;
      border-radius: 10px;
      background: #3a3482;
      border: none;
      line-height: 1rem;
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
    }
  }
}

:global .ant-layout-header {
  padding: 0 20px !important;
}

.activate-link {
  white-space: nowrap;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 500px) {
  :global .ant-layout-header {
    padding: 0 8px !important;
  }
}
