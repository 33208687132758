@import "/public/assets/style/variables";

.buy-more-drawer {
  .title {
    font-size: 22px;
  }
  .buy-more-form-input-wrapper {
    display: flex;
    align-items: center;
    label {
      margin-top: 0px;
      margin-bottom: 0px;
      margin-right: 20px;
    }
    > * {
      margin-top: 0px !important;
    }
  }

  .buy-more-form {
    margin: 0 auto;

    .item {
      .select {
        width: 552px !important;
        margin-top: 0px;
      }

      width: 552px !important;
    }

    .checkbox-block {
      margin-top: 20px;
      display: flex;
      align-items: center;

      @include display-center(flex, flex-start, center);
      label {
        margin-right: 0px !important;
      }
      a {
        color: $link-color;
        display: inline-block;
      }

      a:hover {
        color: $link-hover-color;
      }
    }

    .paypal-loading {
      padding: 0 0 20px 0;
      @include display-center(flex, center, center);
    }

    .paypal-open {
      overflow: hidden;
      animation: paypal 0.2s linear forwards;
    }

    .paypal-close {
      overflow: hidden;
      animation: p-close 0.2s linear forwards;
    }

    .ineco-button {
      // background-color: #14442b;
      background-color: white;
      color: black;
      border-radius: 6px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      padding: 5px 20px;
      font-weight: 500;
      margin: 10px 0;
      border: 1px solid;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      img {
        width: 100px;
        margin: 0 10px;
      }

      span {
        font-size: 12px;
        font-weight: 400;
      }
    }

    .pixi-payment-button {
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      background-color: #636363;
      color: #fff;
      cursor: pointer;
      text-align: center;
      margin: 10px 0;
      border-radius: 5px;
      padding: 8px;
      font-weight: 700;
      font-size: 18px;
    }

    @keyframes paypal {
      0% {
        height: 0;
      }

      100% {
        min-height: 185px;
      }
    }

    @keyframes p-close {
      0% {
        height: 185px;
      }

      100% {
        height: 0;
      }
    }

    .custom-open {
      overflow: hidden;
      height: 115px;
      transition: height 0.2s;
      margin-bottom: 20px;
    }

    .custom-close {
      transition: height 0.2s;
      overflow: hidden;
      height: 0;
    }

    .custom-transfer {
      width: 552px;
      height: 115px;
      background-color: #e9e9e9;
      border-radius: 5px;
      @include display-center(flex, center, center);

      .custom-transfer-container {
        @include display-flex-direction(flex, column, center);

        .activations-link-block {
          width: 200px;
          height: 35px;
          border-radius: 3px;
          @include display-center(flex, center, center);
          background-color: $primary-dark;

          .activations-link-icon {
            font-size: 17px;
            color: $color;
          }

          a {
            margin-left: 5px;
            margin-bottom: 3px;
            display: inline-block;
            color: $color;
            font-size: 15px;
          }
        }

        .custom-transfer-text {
          width: 490px;
          text-align: center;
          padding-bottom: 10px;
          font-size: 12px;
        }
      }
    }
  }
}
