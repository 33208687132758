@import "/public/assets/style/variables";

.dashboard-page-card {
  background: #fff;

  background-size: cover;
  border-radius: 10px;
  box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
  color: #fff;
  display: flex;
  flex-direction: column;
  opacity: 1;
  border-radius: 0.375rem;
  padding: 10px 24px;
  position: relative;
  width: 100%;
  font-size: 1rem;
}
