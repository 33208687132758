@import "/public/assets/style/variables";

.header {
  @include display-center(flex, space-between, center);
  background: $secondary-background;
  .header-item-block {
    @include display-center(flex, space-between, center);
  }
  .line-block {
    transform: rotate(180deg);
    width: 0.8px;
    height: 30px;
    margin: 0 15px 0 15px;
    background-color: $primary-dark;
  }
}

.logo {
  width: 130px;
}

.refresh-btn {
  cursor: pointer;
  margin-left: 10px;
}

@media screen and (max-width: 500px) {
  .line-block {
    margin: 0 7px !important;
  }

  .credit-refresh-btn-block {
    font-size: 10px;
  }
}
