body {
  background: #f8f7fa;
}

.table-action-button {
  width: 30px;
  height: 30px;
  background: #7367f0 !important;
  border: none !important;
  border-radius: 50% !important;
}

.css-yrdy0g-MuiDataGrid-columnHeaderRow {
  background-color: #f6f6f7 !important;
}
.table-action-button svg {
  width: 30px !important;
  fill: #fff !important;
}
.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
  color: #7367f0 !important;
}

.separator:after {
  content: "";
  position: absolute;
  width: 43%;
  height: 1px;
  background: gray;
  transform: translate(4%, -50%);
  top: 50%;
}
.ant-btn-primary {
  background-color: #7b70f1 !important;
  border-radius: 5px !important;
  color: #fff !important;
}
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: #7b70f1 !important;
}
.css-n3fyjk-MuiDataGrid-root .MuiDataGrid-row.Mui-selected {
  background-color: #f7f5ff !important;
}
.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:active,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:hover {
  background-color: #7b70f181 !important;
}
.datagrid p {
  margin-bottom: 0px !important;
}
.ant-modal-body {
  padding-bottom: 7px !important;
}
.separator:before {
  content: "";
  position: absolute;
  width: 43%;
  height: 1px;
  background: gray;
  transform: translate(-105%, -50%);
  top: 50%;
}

.separator {
  width: 100%;
  text-align: center;
  position: relative;
}

/* header area */
.header-upper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: #ffffff;
  border-bottom: 1px solid #ddd;
  height: 100%;
}

.header-upper > div {
  width: 90%;
  padding: 1rem 0rem;
  max-width: 1416px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ant-layout .ant-layout-header {
  padding: 0px !important;
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover {
  background-color: #f4f3fe !important;
  color: #7368f0 !important;
}
.css-6hp17o-MuiList-root-MuiMenu-list {
  padding: 10px !important;
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
  color: #fff !important;
  background-color: #7367f0 !important;
}
input[type="number"]:focus {
  border-color: #7367f0 !important;
  box-shadow: 0px 2px 6px 0px rgba(47, 43, 61, 0.14) !important;
  /* box-shadow: none !important; */
}
.css-1w1rijm-MuiButtonBase-root-MuiButton-root {
  background-color: #e8e7fd !important;
  color: #7367f0 !important;
}
.row-disabled {
  background-color: #fde6e6 !important;
}

.profileDrawer .ant-form-item {
  margin-bottom: 0px;
}

/* body .form-wrapper-profile .MuiInputBase-root.Mui-disabled {
  background: transparent !important;
}

body .form-wrapper-profile .MuiInputBase-root.Mui-disabled input {
  color: #fff !important;
} */
.darkmode .ant-modal-content div .ant-form-item-explain-error {
  color: #ff4d4f !important;
}
