@import "/public/assets/style/variables";

.button {
  @include button();
  //@include display-center(flex, center, center);
  //.for-item-buttons {
  //  width: 220px;
  //  @include display-center(flex, space-between, center);
  //  Button {
  //    width: 100px;
  //  }
  //}
  display: flex;
  width: 100%;
}

.input-flex-area {
  display: flex;
  align-items: center;
  :disabled {
    background-color: transparent;
    opacity: 0.5;
  }
  label {
    min-width: 40px;
    width: 40px;
    margin-right: 20px;
  }
  > * {
    margin-top: 0px !important;
  }
}
