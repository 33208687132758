@import "/public/assets/style/variables";

.reset-block {
  .form-input-block {
    :global .ant-form-item {
    }
  }
  .reset-button {
    @include button();
    width: 100%;
    margin-top: 1rem;
    display: flex;
  }
}

.input-flex-area {
  display: flex;
  align-items: center;
  :disabled {
    background-color: transparent;
    opacity: 0.5;
  }
  label {
    min-width: 100px;
    width: 100px;
    margin-right: 20px;
  }
  > * {
    margin-top: 0px !important;
  }
}
