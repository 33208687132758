@import "/public/assets/style/variables";

:global .ant-form {
  height: auto;
}

Form {
  Button {
    width: 100%;
    margin: 0 auto;
  }

  .form-item {
    text-align: right;

    a {
      color: #7367f0;
      // color: $link-color;
    }
    a:hover {
      color: #5c53bb;
      // color: $link-hover-color;
    }
  }
  .login-btn {
    border: none;
    background-color: #7367f0;
    &:hover {
      background-color: #5c53bb !important;
    }
  }
  .form-input-block {
    :global .ant-form-item {
      height: 62px !important;
      margin-bottom: 2px;
    }
  }
}

.login-form_google-recaptcha {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
