@import "/public/assets/style/variables";

.current-device-button {
  @include button();
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  button {
    height: 40px;
    border: none;
    font-size: 16px;
    width: 120px;
  }
}
.packages-select {
  width: 100% !important;
}

.protected {
  margin-bottom: 18px;
  display: flex;
  align-items: center;
  label {
    margin-right: 0px;
  }
}

.input-flex-area {
  display: flex !important;
  align-items: center;
  label {
    width: 150px;
  }
}
