@import "/public/assets/style/variables";

.ant-menu-inline .ant-menu-item:not(:last-child),
.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child) {
  margin-bottom: 0px !important;
}

.ant-menu-item {
  font-weight: 500 !important;
  font-size: 15px !important;
  border-radius: 10px;
}

.ant-menu-item-selected {
  background: linear-gradient(to right, #7368f0, #9e95f6);
  color: #fff;
}

.ant-menu-vertical {
  border-right: none !important;
}

.navbar {
  background-color: #fff;
  padding: 5px 0px;
  border-bottom: 1px solid #dbdade;
}
.navbar > * {
  width: 90%;
  max-width: 1450px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.navbar > *::after,
.navbar > *::before {
  display: none;
}

.ant-menu-vertical .ant-menu-item::after {
  display: none !important;
}
.sidebar {
  width: 100%;

  position: relative;

  .menu {
    background: transparent;
    .referral-icon {
      transform: rotate(45deg);
    }
  }

  .sidebar-logo {
    min-height: 120px;
    @include display-center(flex, center, center);
    cursor: pointer;

    img {
      margin-bottom: 30px;
      margin-top: 30px;
      min-width: 50px;
      width: 50% !important;
    }
  }

  .sidebar-button-block {
    width: 90%;
    position: absolute;
    top: 93%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    .sidebar-button-block-text {
      font-size: 12px;
      color: $color;
    }

    .sidebar-button-descr {
      height: 30px;
      padding: 0 5px 0 5px;
      @include display-center(flex, center, center);
    }

    .sidebar-link-block {
      height: 35px;
      border-radius: 3px;
      @include display-center(flex, center, center);
      //background-color: $primary-color;
      padding: 0 15px;

      .sidebar-link-icon {
        font-size: 14px;
        color: $color;
      }
    }

    .close {
      display: none;
    }

    .sidebar-button-descr p:nth-child(1) {
      text-align: center;
      color: $color;
      font-size: 11.6px;
      white-space: nowrap;
      overflow: hidden;
      width: 170px;
      animation: type 0.2s steps(50, end) forwards;
    }

    a {
      margin-left: 5px;
      margin-bottom: 3px;
      display: inline-block;
      color: $color;
      font-size: 15px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      // width: 120px;
      animation: link-type 0.2s steps(50, end) forwards;
    }

    @keyframes type {
      0% {
        width: 0;
      }

      100% {
        border-right: none;
      }
    }

    @keyframes link-type {
      0% {
        width: 0;
      }

      100% {
        border-right: none;
      }
    }
  }
}

.ant-item {
  position: relative;
  overflow: unset !important;

  .dropdown-area {
    position: absolute;
    background-color: #fff;
    left: 0px;
    width: 200px;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    z-index: -1;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    opacity: 0;
    top: 20px;
    transition: 0.3s ease all;
    li {
      padding: 0px 10px;
      color: #000;

      &:hover {
        color: #857af1;
        background-color: #f3f3fe;
      }
      &:first-child {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
      &:last-child {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }

  &:hover {
    .dropdown-area {
      top: 40px;
      opacity: 1;
      z-index: 2;
    }
  }
}

.ant-item {
  .ant-menu-title-content {
    a {
      color: #6f6b7d !important;
    }
  }
}

.ant-menu-item-selected {
  .ant-menu-title-content {
    a {
      color: #fff !important;
    }
  }
}

.sidebar-subreseller-icon {
  svg {
    color: #fff !important;
  }
}

.ant-menu-item {
  .anticon {
    color: #6f6b7d !important;
  }
}

.ant-menu-item-selected {
  .anticon {
    color: #fff !important;
  }

  .sidebar-subreseller-icon {
    svg {
      color: #fff !important;
    }
  }
}
.res-ref-version {
  color: $color;
  font-size: 11px;
}

.ant-menu {
  &:not(.ant-menu-horizontal) {
    .ant-menu-item-selected {
      background-color: #ffc82f !important;
    }
  }
}

@media screen and (max-width: 800px) {
  .ant-layout-sider.ant-layout-sider-dark {
    max-width: 290px !important;
    width: 290px !important;
  }
}
