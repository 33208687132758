@import "/public/assets/style/variables";

.profile-block {
  cursor: pointer;
  img {
    width: 35px;
    margin-right: 10px;
  }
}
.totalwrapper {
  width: 200px;
}
.totalwrapper > * {
  padding-top: 8px;
  padding-bottom: 8px;
}
:global .danger-button {
  background-color: $danger-color;
}
.totalwrapper > *:hover {
  background-color: #f4f3fe;
}

.totalwrapper > *:hover span {
  color: #7367ef;
}
@media screen and (max-width: 500px) {
  .profile-name {
    font-size: 10px;
  }

  .profile-block {
    img {
      width: 30px;
      margin-right: 5px;
    }
  }
}
