@import "/public/assets/style/variables";

.login-page {
  // @include display-center(flex, space-between, center);
  display: grid;
  grid-template-columns: 1fr 0.8fr;
  width: 100%;
  height: 100vh;
  .login-page-left-side {
    background-color: #f8f7fa;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @include background();
  .login-page-illustration-container {
    width: 100%;
    display: none;
    img {
      width: 550px;
    }
  }
  .login-form-block {
    @include display-center(flex, center, center);
    width: 100%;
    .login-form-block-container {
      align-items: center;
      backdrop-filter: blur(0.8rem);
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      min-width: 450px;
      padding: 30px 0 10px;
      width: 470px;
    }
    img {
      width: 200px;
      padding: 0 0 20px 0;
    }
    .login-page-form {
      width: 80%;
    }
  }

  @media (max-width: 992px) {
    .login-page-illustration-container {
      display: none;
    }
  }

  @media (max-width: 576px) {
    .login-form-block {
      .login-form-block-container {
        width: 360px;
        min-width: 310px;
      }
    }
  }
}

@media (max-width: 992px) {
  .login-page {
    grid-template-columns: 1fr;
    > *:first-child {
      display: none;
    }
  }
}
