body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  /*box-sizing: border-box;*/
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
  /*  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
  /*  sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.full-screen-loading {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 9999;

  backdrop-filter: blur(15px);
  background: rgba(0, 0, 0, 0.929);
  background-color: hsla(0, 0%, 0%, 0.936);
  display: flex;
  justify-content: center;
  padding: 30px;
}
.MuiDataGrid-columnSeparator--sideRight {
  display: none !important;
}

.ant-modal-confirm-body-wrapper {
  padding-bottom: 1rem;
}
.ant-modal-confirm-body .ant-modal-confirm-title {
  font-size: 18px !important;
}
.ant-modal-confirm .ant-modal-confirm-btns > .ant-btn:first-child {
  color: #7367f0 !important;
  background-color: #e8e7fd !important;
  border: none;
  height: 40px;
  width: 100px;
  font-size: 17px;
}
.ant-modal-confirm .ant-modal-confirm-btns .ant-btn + .ant-btn {
  color: #fff !important;
  background-color: #7367f0 !important;
  border: none;
  height: 40px;
  width: 100px;
  font-size: 17px;
}
.darkmode .header-upper {
  background-color: #2e3249;
  background: #25293b;
  border-color: #35394f;
}
.darkmode .header-upper,
.darkmode .header-upper * {
  color: #aeb0cc !important;
}
.darkmode .header-upper .header_line-block__5\+BoR {
  background-color: #aeb0cc !important;
}
.darkmode .ant-dropdown-menu {
  background-color: #2f3349;
}
.darkmode .ant-dropdown-menu * {
  color: #aeb0cc;
}
.darkmode .ant-dropdown-menu li:hover {
  background: #35394f;
}
.darkmode .navbar {
  background-color: #25293b;
  border-color: #35394f;
}
.darkmode .ant-item .dropdown-area {
  background-color: #2f3349;
  box-shadow: none;
}
.darkmode .ant-item .dropdown-area li:hover {
  background: #35394f;
}
.darkmode .ant-item .dropdown-area * {
  color: #aeb0cc;
}
.darkmode .ant-item .ant-menu-title-content a:not(.active) {
  color: #aeb0cc !important;
}
.darkmode .ant-menu-item svg path {
  fill: #aeb0cc !important;
}

.darkmode .css-n3fyjk-MuiDataGrid-root .MuiDataGrid-withBorderColor {
  background-color: #4a5072;
}
.darkmode .css-n3fyjk-MuiDataGrid-root .MuiDataGrid-cell {
  border-color: #4a4c64;
}
.darkmode .css-n3fyjk-MuiDataGrid-root .MuiDataGrid-withBorderColor {
  border-color: #4a4c64 !important;
}
.darkmode .css-n3fyjk-MuiDataGrid-root .MuiDataGrid-container--top [role="row"],
.darkmode
  .css-n3fyjk-MuiDataGrid-root
  .MuiDataGrid-container--bottom
  [role="row"] {
  border-color: #4a4c64 !important;
}

.darkmode .ant-layout {
  background-color: #25293b;
  background: #25293b !important;
}
.darkmode .card_dashboard-page-card__oZxb0 {
  background-color: #2f3349;
}
.darkmode .card_dashboard-page-card__oZxb0 * {
  color: #aeb0cc;
}
.darkmode .card_dashboard-page-card__oZxb0 * tspan {
  fill: #fff;
}

.darkmode .css-n3fyjk-MuiDataGrid-root {
  border-color: #35394f;
}
.darkmode .ant-item .ant-menu-title-content a:not(.active) {
  color: #aeb0cc !important;
}
.darkmode .ant-modal-content {
  background-color: #25293b;
}
.darkmode
  .ant-modal-content
  *:not(.validateMac_error-text__W-uf5, button, button span, button svg) {
  color: #aeb0cc !important;
}

.darkmode .pages_header__lZapf .pages_title__y5RwV,
.darkmode .pages_header__lZapf .pages_title__y5RwV span {
  color: #fff !important;
}
.css-1d6wzja-MuiButton-startIcon > *:nth-of-type(1) {
  color: #7367f0;
}
.css-1d6wzja-MuiButton-startIcon > *:nth-of-type(1) * {
  color: #7367f0;
}
.darkmode .ant-modal-header {
  background-color: #2f3349;
}
.darkmode .ant-tag-red {
  color: #cf1322 !important;
}

.darkmode
  .css-141ilzo-MuiFormControl-root-MuiTextField-root
  .MuiInputBase-input {
  color: #aeb0cc;
}
.darkmode .css-1w53k9d-MuiDataGrid-overlay {
  background-color: #25293b;
}
.darkmode .css-n3fyjk-MuiDataGrid-root {
  --DataGrid-rowBorderColor: #35394f;
}
.darkmode .MuiDataGrid-footerContainer {
  background-color: transparent !important;
}
.darkmode .css-n3fyjk-MuiDataGrid-root .MuiDataGrid-row.Mui-selected {
  background-color: #25293b !important;
}

/* 4A5072 */
.darkmode
  .css-141ilzo-MuiFormControl-root-MuiTextField-root
  .MuiInputBase-root {
  border-color: #4a4c64;
}
.darkmode .ant-table-content {
  background-color: #25293b;
}

.darkmode
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr
  > th {
  background-color: #4a5072;
}

.custom-tooltip * {
  color: #000 !important;
}
