@import "/public/assets/style/variables";

Form {
  .form-items {
    text-align: right;

    Button {
      width: 100%;
      margin: 0 auto;
      background-color: #7367f0;
      border: none;
      &:hover {
        background-color: #5c53bb;
      }
    }

    a {
      color: #7367f0 !important;

      &:hover {
        color: #5750ad !important;
      }
    }
  }

  .form-input-block {
    :global .ant-form-item {
      height: 62px !important;
      margin-bottom: 2px;
    }
  }
}
