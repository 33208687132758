@import "/public/assets/style/variables";

.mac-inp {
  width: 100%;
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  outline: none;
}

.message-open {
  overflow: hidden;
  transition: height 0.2s;
  background-color: rgb(242, 206, 206);
  padding: 10px;
  margin-top: 10px;
  border-radius: 9px;
}

.message-close {
  transition: height 0.2s;
  overflow: hidden;
  padding: 0px;
  height: 0;
}

.success-text {
  color: $success-color;
}

.error-text {
  color: $danger-color;
}

.input-flex-area {
  display: flex;
  align-items: center;
  label {
    min-width: 120px;
    width: 120px;
    margin-right: 20px;
  }
  > * {
    margin-top: 0px !important;
  }
}
