@import "/public/assets/style/variables";

.profile-input-block {
  .item {
    .select {
      width: 265px !important;
    }
    width: 265px !important;
  }
  Input {
    width: 265px !important;
  }
  button {
    // width: 100%;
    // margin-top: 1rem;
    // display: flex;
  }
  .drawer-open {
    position: absolute;
    top: 0;
    left: 50px;
    cursor: pointer;
    color: $link-color;
    z-index: 100;
  }
}

.form-input-block {
  display: grid;
  // grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.input-flex-area {
  display: flex;
  align-items: center;
  :disabled {
    background-color: transparent;
    opacity: 0.5;
  }
  label {
    min-width: 180px;
    width: 180px;
    margin-right: 20px;
  }
  > * {
    margin-top: 0px !important;
  }
}

.drawer-button {
  width: 100%;
  margin: auto;
  display: flex;
}

:global .ant-drawer-header-title {
  height: 50px !important;
}

@media screen and (max-width: 600px) {
  .form-input-block {
    grid-template-columns: 1fr;
  }
}
