@import "/public/assets/style/variables";

.activate-checkbox-block {
  display: flex;
  align-items: center;
  @include display-center(flex, "", center);
  gap: 10px;
  > * {
    margin-bottom: 0px;
  }
  label {
    margin-right: 0px;
  }
  a {
    margin-bottom: 0px !important;
  }

  a {
    color: $link-color;
  }
  a:hover {
    color: $link-hover-color;
  }
}

.activate-button {
  @include button();
  display: flex;
  width: 100%;
  margin-top: 10px;
  button {
    height: 40px;
    width: 150px;
    border: none;
    font-size: 16px;
  }
}
.packages-select {
  width: 100% !important;
}
.input-flex-area {
  display: flex;
  align-items: center;
  label {
    width: 120px;
    min-width: 120px;
    margin-right: 20px;
  }
  > * {
    margin-top: 0px !important;
  }
}
