@import "/public/assets/style/variables";

.dashboard-title {
  font-weight: 700;
  font-size: 19px;
}

.reseller-statistics-cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 25px;
  margin-bottom: 20px;

  &__content {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 1px 8px;
    background: #3a3482;
    border-radius: 0.4rem;
    font-weight: 500;

    &__title {
      color: #5d596c;
      margin-bottom: 0;
    }
    &__para {
      color: #5d596c;
      font-size: 12px;
    }
    &__todayprice {
      color: #5d596d;
      font-size: 40px;
      margin-top: 20px;
      font-weight: 600;
      margin-bottom: 0px;
    }
    &__todaytext {
      color: #6f6b7d;
      font-size: 12px;

      padding-bottom: 30px;
    }
    &__gridChart {
      display: grid;
      grid-template-columns: 1fr 1.3fr;
      align-items: flex-end;
    }
    &__statisticsarea {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      border: 1px solid #dbdade;
      border-radius: 5px;
      margin-bottom: 1rem;
      padding: 14px;
      p {
        color: #5d596d;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 0px;
      }
      h1 {
        display: flex;
        align-items: center;
        gap: 5px;
        font-weight: 500;
        font-size: 13px;
        color: #5d596c;
        margin-bottom: 0px;
        span {
          background-color: purple;
          width: 10px;
          height: 10px;
          border-radius: 50%;
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .reseller-statistics-cards {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 600px) {
  .reseller-statistics-cards {
    grid-template-columns: 1fr;
  }
}
